.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Add this inside your tailwind.config.js under the extend section or in your CSS file */
@keyframes bounce {

  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-white-container {
  position: relative;
  background-color: white;
  width: 100px;
  /* Adjust width as needed */
  height: 100px;
  /* Adjust height as needed */
  overflow: hidden;
  /* Hide the overflow */
}

.fa-comment-icon {
  position: absolute;
  top: -50%;
  /* Adjust this value to position half of the icon outside */
  left: 50%;
  /* Center the icon horizontally */
  transform: translateX(-50%);
  /* Adjust the horizontal position */
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.animate-bounce200 {
  animation: bounce 1s infinite 200ms;
}

.animate-bounce400 {
  animation: bounce 1s infinite 400ms;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
